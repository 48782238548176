#content {

  @include media-up($point-small) {
    padding-top: 50px;
    padding-bottom: 20px;

    &.uk-padding-middle-bottom {
      padding-bottom: 50px;
    }
  }
  @include media-down($point-small) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  $list-size: 7px;
  $list-color: darken($grey-color, 15%);

  ul:not(.uk-breadcrumb):not(.uk-pagination):not(.uk-thumbnav):not(.uk-dotnav):not(.uk-slider-items) {
    padding-left: 0;

    li {
      list-style: none;
      margin-left: 0;
      padding-left: 1em;
      text-indent: -1em;

      &:before {
        display: inline-block;
        content: "";
        width: $list-size;
        height: $list-size;
        border: 1px solid $list-color;
        margin-right: 10px;
        transform-origin: center;
        transform: rotate(45deg);
        padding: 3px;
        box-sizing: border-box;
      }
    }
  }

  ol {
    padding-left: 0;
    counter-reset: item;

    li {
      list-style-type: none;
      counter-increment: item;

      &:before {
        content: counter(item) ".   ";
        @extend .font-size-small;
        color: $list-color;
      }
    }
  }
}

body {
  &:focus, &:active {
    #content, .focus-blocked {
      color: $clear-color;
    }
  }
}

.mobile {
  #content {
    padding-right: 30px;
    padding-left: 30px;
  }
}

/*#toTop {
  transform-origin: center;
  transition: .2s linear;

  &.animation {
    transform: scale(1.45); //1.5
  }
}*/

// RAU
.font-hero {
  @extend .font-size-xlarge;
  @extend .font-weight-thin;
  @include media-down($point-mini) {
    font-size: 25px;
    line-height: 25px;
  }
}


p > abs {
  @include media-up($point-tablet) {
    display: none;
  }
  @include media-down($point-tablet) {
    display: block;
  }
}

.m-header {
  @include media-up($point-mini) {
    color: $clear-color;
  }
  @include media-down($point-mini) {
    color: $black-color;
  }
}

.m-navbar .uk-icon {
  @include media-down($point-mini) {
    color: aliceblue;
  }
}

.m-steve-macconeil {
  top: 40%;
  line-height: 38px;
  @include media-down($point-mini) {
    line-height: 19px;
  }
}

.m-transform-75 {
  transform-origin: center;
  transform: scale(0.75);
}

.m-article-center {
  @include media-up($point-tablet) {
    position: relative;
    left: -35px;
  }
}