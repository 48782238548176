footer {
  @extend .font-size-default;
  @extend .uk-text-grey;
}

//@include media-up($point-small) {
  html.sticky-footer {
    position: relative;
    //height: 100%;
    min-height: 100%;

    body {
      height: 100%;
      //margin-bottom: $footer-height;
      padding-bottom: $footer-height;
    }

    footer {
      position: absolute;
      width: 100%;
      height: $footer-height;
      bottom: 0;
    }
  }
//}