#mainMenu {
  padding-bottom: 20px;
  z-index: 999;
  &.uk-active {
    border-bottom: 1px $grey-color solid;
  }
  .uk-navbar-nav>li a {
    padding-top: 56px !important;
    padding-bottom: 0px !important;
    line-height: normal;
    font-size: 16px;

    @include media-down($point-extra-large) {
      //text-shadow: 0px 0px 1px rgba($black-color, 1);
      color: $clear-color !important;
    }

  }
}

#mobileMenu {
  z-index: 999;
  &.uk-active {
    border-bottom: 1px darken($grey-color,35%) solid;
  }
}

#pageLogo {
  margin-top: 10px;
  img {
    height: 70px;
  }
}
