$point-extra-large: 1600px;
$point-large: 1200px;
$point-tablet: 1024px + 1;
$point-medium: 960px;
$point-mini: 840px;
$point-medium-small: 737px;
$point-small: 640px;
$point-extra-small: 325px;

$primary-color: #493E3D;
$secondary-color: mediumaquamarine; //#FF5600;
$clear-color: white;
$black-color: #0C0300; //#121217; 120400
$grey-color: #8f8988;
$base-color: $clear-color;
$active-color: paleturquoise;

$footer-height: 55px;

//FONT STYLES
.f-family {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

$weighs: (
        'default':300,
        'medium':400,
        'thin':100
);
@each $index, $weight in $weighs {
  .font-weight-#{$index} {
    font-weight: #{$weight};
  }
}

$sizes: (
        'xlarge':35,
        'large':24,
        'default':17,
        'medium':15,
        'small':13
);
@each $index, $size in $sizes {
  .font-size-#{$index} {
    font-size: #{$size}px;
  }
}

// GLOBAL STYLES
$edges: ('top', 'right', 'bottom', 'left');
@each $index in $edges {
  .uk-margin-xsmall-#{$index} {
    margin-#{$index}: 5px;
  }

  .uk-margin-xsmall {
    margin: 5px;
  }

  .uk-padding-xsmall-#{$index} {
    padding-#{$index}: 5px;
  }

  .uk-padding-xsmall {
    padding: 5px;
  }

  .uk-padding-middle-#{$index} {
    padding-#{$index}: 50px;
  }

  .uk-padding-middle {
    padding: 50px;
  }
}

$colors: (
        base: $base-color,
        primary: $primary-color,
        secondary: $secondary-color,
        grey: $grey-color,
        black: $black-color,
        clear: $clear-color,
        active: $active-color,
        orange: #FF5600,
        lightgrey: #17171d,
        darkgrey: #1C1C22
);

@each $index, $color in $colors {
  .uk-tile-#{$index} {
    background-color: $color !important;
  }
  .uk-text-#{$index} {
    color: $color !important;

    * {
      color: $color;
    }
  }
}

img.j-svg {
  height: 0;
  min-height: 0;
}

.g-greyfilter {
  //-ms-filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  filter: grayscale(1);

  &_hover {
    > * > img {
      @extend .g-greyfilter;

      &:hover {
        -webkit-filter: none;
        -moz-filter: none;
        -o-filter: none;
        filter: none;
      }
    }
  }
}

a.g-greyfilter_hover {
  > img {
    @extend .g-greyfilter;
  }

  &:hover {
    > img {
      -webkit-filter: none;
      -moz-filter: none;
      -o-filter: none;
      filter: none;
    }
  }
}

.g-text-normalcase {
  text-transform: none !important;
}

.g-text-unstressed {
  text-decoration: none !important;
}

.g-text-shadow {
  text-shadow: -1px 0px 9px rgba($black-color, 1);
  //-webkit-text-stroke: 1px rgba($base-color,0.7);
}

.g-text-shadow_clear {
  text-shadow: -1px 0px 9px rgba($clear-color, 1);
}

.uk-tile-content {
  background-color: rgba(37,35,35, .85);
}