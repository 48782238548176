html {
  background-color: $black-color;
}

* body {
  height: 100%;
  quotes: "\201c" "\201d";
  @extend .f-family;
  @extend .font-weight-default;
  @extend .font-size-default;
  min-height: 100vh;

  color: $clear-color;

  background-color: $black-color;
  background-position: top right, top left;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
  background-image: url("../img/triangle.svg"), url("../img/triangle-reverse.svg");

  &.m-backgroud_fire {
    background-image: url("../img/triangle-fire.svg"), url("../img/triangle-reverse-fire.svg");
  }

  &.m-backgroud_contrasty {
    background-image: url("../img/triangle-contrasty.svg"), url("../img/triangle-reverse-contrasty.svg");
  }

  &.m-home {
    background-image: none;
  }

  /*  &.mobile {
      @extend .font-size-small;

      &.page .uk-sticky-placeholder {
        height: 50px !important;
      }

      &.home .uk-sticky-placeholder {
        height: 0px !important;
      }
    }*/
}

* {
  &:focus, &:active {
    outline: none;
    color: inherit;
    text-decoration: none !important;
  }

  &::selection {
    @extend .uk-tile-base;
  }

  :hover {
    outline: none;
  }
}

a {
  //text-decoration: underline;
  color: $grey-color;

  &:hover {
    color: $secondary-color;

    > * {
      color: $secondary-color;
    }

    > svg > polygon {
      fill: $secondary-color;
    }
  }
}

strong, b {
  @extend .font-weight-medium;
}

em, i {
  color: inherit;
}

small {
  font-size: 70%;
}

h1, .uk-article-title {
  @extend .font-weight-thin;
  @extend .font-size-large;
  text-shadow: -1px 0px 9px rgba($black-color, 1);
  @include media-up($point-mini) {
    color: $clear-color;
  }
  @include media-down($point-mini) {
    font-size: 1.2rem;
    //color: $black-color;
    color: $clear-color;
    //text-shadow: 0px 0px 1px rgba($clear-color, 1);
  }
}

h2, .uk-h2 {
  @extend .font-weight-thin;
  @extend .font-size-large;
  color: $clear-color;
}

h3, .uk-h3 {
  @extend .font-weight-default;
  @extend .font-size-default;
  color: $grey-color;
}

input[placeholder] {
  text-overflow: ellipsis;
}

input::-moz-placeholder, input:-moz-placeholder, input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

blockquote {
  $blockquote-color: lighten($base-color, 15%);
  $blockquote-size: 32px;
  font-weight: 300;

  padding: 0 15px 0 20px;
  font-style: normal;

  p {
    margin-bottom: 10px;
  }

  * + p {
    margin-top: 10px;
  }

  p:first-child {
    position: relative;

    &:before {
      position: absolute;
      top: -15px;
      left: -15px;
      font-size: $blockquote-size;
      color: $blockquote-color;
      content: "\201c";
    }
  }

  p:last-child {
    position: relative;
    top: -20px;

    &:after {
      position: relative;
      top: 10px;
      right: -5px;
      font-size: $blockquote-size;
      color: $blockquote-color;
      content: "\201d";
    }
  }

  &.uk-text-small {
    font-size: 13px;
    white-space: pre-line;
    padding-left: 0;
  }

  @include media-up($point-small) {
    font-size: inherit; //1.05rem;
  }
  @include media-down($point-small) {
    font-size: inherit;
  }
}

.b-mosaic {
  background-image: url(../img/mosaic.jpg);
  background-position: bottom center;
  background-size: contain;
  color: $base-color;

  &-inverse {
    background-image: url(../img/mosaic-bg.jpg);
  }

  &-thin {
    background-image: url(../img/mosaic-thin.jpg);
    background-repeat: repeat-y;
  }
}

.g-greyfilter {
  //-ms-filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  filter: grayscale(1);

  &_hover {
    img {
      @extend .g-greyfilter;

      &:hover {
        -webkit-filter: none;
        -moz-filter: none;
        -o-filter: none;
        filter: none;
      }
    }
  }
}

.map * {
  touch-action: auto;
}

@include media-down($point-small) {
  .b-margin-top-mobile {
    margin-top: 20px;
  }
}

#clients {
  img {
    max-height: 100px;
  }
}