* + .uk-h1, * + .uk-h2, * + .uk-h3, * + .uk-h4, * + .uk-h5, * + .uk-h6, * + h1, * + h2, * + h3, * + h4, * + h5, * + h6 {
  margin-top: 20px;
}

.uk-h1, .uk-h2, .uk-h3, .uk-h4, .uk-h5, .uk-h6, h1, h2, h3, h4, h5, h6 {
  @extend .f-family;
}

.uk-input:focus, .uk-select:focus, .uk-textarea:focus {
  border-color: $secondary-color;
}

.uk-navbar-nav {
  min-height: 0 !important;

  > li {
    > a {
      padding: 15px !important;
    }

    &.uk-active {
      > a {
        color: $clear-color !important;
      }
    }

    a {
      text-transform: lowercase;
      color: $active-color;
      min-height: auto;
      @extend .f-family;
      @extend .font-size-default;
    }
  }

  li {
    &:hover {
      > a {
        color: $secondary-color !important;
      }
    }
  }
}

.uk-offcanvas-bar {
  @extend .uk-tile-darkgrey;

  .uk-nav-default .uk-nav-sub a {
    color: $clear-color;

    &:hover {
      text-decoration: underline;
    }
  }
}

.uk-navbar-item {
  color: $clear-color;
  min-height: auto;

  a {
    color: $clear-color;

    &:hover {
      color: $secondary-color;
    }
  }
}

.uk-modal {
  background: rgba($black-color, .97);

  &-title {
    font-size: 1.5rem;
    @include media-down($point-small) {
      font-size: 1rem;
      font-weight: 400;
    }
  }
}

.uk-link-reset {
  &:hover {
    text-decoration: underline;
  }
}

.uk-link {
  text-decoration: underline;
  color: $secondary-color;
  &:hover {
    color: darken($secondary-color, 15%);
  }
}

button.uk-search-icon svg {
  position: relative;
  top: 0;
  right: 15px;
}

.uk-card {
  &-default {
    color: $base-color;
  }
}

.uk-card-body {
  .uk-card-title {
    margin-bottom: 10px;
    font-size: .9rem;
    line-height: 1.1;
    color: darken($secondary-color, 5%) !important;
    background-color: $clear-color;

    + p {
      margin-top: 5px;
      color: $base-color;
      line-height: 18px;
      font-weight: 300;
      overflow: hidden;
    }
    +p:not(.b-home-block){
      height: 33px;
    }
  }
}

.uk-card-small .uk-card-body, .uk-card-small.uk-card-body {
  padding: 15px;
}

.uk-grid-small > * {
  @include media-up($point-small) {
    padding-left: 5px;
  }
  @include media-down($point-small) {
    padding-left: 0;
  }
}

.uk-light .uk-slidenav {
  background: rgba($grey-color, .5);
}

.uk-overlay-icon.uk-icon {
  color: $secondary-color;
}

.uk-navbar-dropdown {
  width: auto;

  &-nav > li a {
    line-height: 19px;
    white-space: nowrap;
  }

}

.uk-tooltip.uk-active {
  background-color: $base-color;
}

.uk-button {

  &-fire {
    color: $black-color;
    background-color: $active-color;

    &:hover, &:focus, &:active {
      color: $clear-color;
      background-color: $secondary-color;
    }

    &:disabled {
      color: $clear-color;
      background-color: $grey-color;

      &:hover {
        color: $clear-color;
        background-color: $grey-color;
      }
    }
  }

  &-primary {
    color: $clear-color;
    background-color: $grey-color;

    &:hover, &:focus, &:active {
      color: $clear-color;
      background-color: $secondary-color;
      * {
        color: $clear-color;;
      }
    }

    &:disabled {
      color: $clear-color;
      background-color: $grey-color;

      &:hover {
        color: $clear-color;
        background-color: $grey-color;
      }
    }
  }

  &-secondary {
    color: $clear-color;
    background-color: $base-color;

    &:hover, &:focus, &:active {
      color: $clear-color;
      background-color: $secondary-color;
      * {
        color: $clear-color;;
      }
    }

    &:disabled {
      color: $clear-color;
      background-color: $base-color;
    }
  }

  &-default {
    border-color: $grey-color;
    &:hover, &:focus, &:active {
      color: $clear-color !important;
      border-color: $clear-color;
    }
  }
}

.uk-navbar-toggle {
  min-height: 50px;
  //@extend .f-family-condensed;
  @extend .f-family;
}

.uk-thumbnav {
  > li {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    > a {
      > img {
        transition: .2s linear;
      }

      &:hover {
        > img {
          transform: scale(1.1);
        }
      }

      &::after {
        background: none;
      }
    }
  }
}

.uk-text-lead {
  font-size: 1.10rem;
  line-height: 1.1;
  color: $base-color;
}

.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
  margin: 0 15px;
}

.uk-pagination {
  > * > :hover {
    color: inherit;
    text-decoration: none;
  }

  li {
    a {
      text-decoration: none;
    }

    span.uk-badge {
      text-align: center;
      color: $clear-color;
      background-color: $primary-color;
    }
  }
}

.uk-overlay-default {
  background: rgba($black-color, .8);
}

.uk-lightbox {
  //background-color: rgba($primary-color, .95);
  @extend .uk-tile-content;

  &-button {
    opacity: 1;
    color: orange !important;
  }

  &-toolbar {
    opacity: 1;
    transform: translateX(0);
    transform: translateY(0);
  }
}

.uk-button-small {
  padding: 5px 5px;
  @include media-up($point-medium) {
    font-size: 12px;
  }
  @include media-down($point-medium) {
    font-size: 11px;
  }
}

.uk-icon.uk-slidenav {
  transform-origin: center;
  transform: scale(1.8);
  color: $base-color;

  &:hover {
    color: $secondary-color;
  }

  @include media-up($point-mini) {
    font-size: 12px;
    top: 35%;
  }
  @include media-down($point-mini) {
    font-size: 11px;
    top: 70%;
  }
}

.uk-section-small {
  @include media-down($point-small) {
    padding-top: 0;
  }
}

.uk-text-success, .uk-text-danger {
  &.message {
    padding: 5px;
    text-align: center;
    background: gray;
    color: whitesmoke !important;
  }
}

.uk-position-bottom-center, .uk-position-top-center {
  display: block;
}

.uk-dotnav > * {
  > * {
    border-radius: 0;
  }

  > :focus, > :hover {
    background-color: rgba($secondary-color, .6);
  }
}

.uk-dotnav > .uk-active > * {
  background-color: $secondary-color;
}

.uk-container-xsmall {
  max-width: 780px;
  //max-width: 820px;
}

.uk-background-cover {
  background-size: cover !important;
}

//RAU MULTIMEDIA SITE
.uk-description-list{
  > dt {
    display: inline-block;
    margin-right: 20px;
    height: 25px;
    float: left;
    color: $clear-color;
  }
  > dd {
    display: inline-block;
    height: 25px;
    margin-top: 20px;
    float: left;
  }
}

.uk-checkbox, .uk-input, .uk-radio, .uk-select, .uk-textarea {
  @extend .font-size-medium;
}

.uk-accordion-title{
  color: inherit;
  &:focus, &:hover {
    width: auto;
  }
}

.uk-visible-toggle:not(:hover):not(.uk-hover) .uk-hidden-hover:not(:focus) {
  visibility: hidden;
}

.uk-badge {
  margin-right: 5px;
  background: transparent;
  border: 1px solid $clear-color;
}