#gl {
  position: absolute;
  top: 1px;
  left: 1px;
  //width: 100%;
  //height: 100%;
  z-index: 1;
}

#sign-mask {
  position: absolute;
  top: 0;
  left: -1px;
  z-index: 2;

  transform-origin: center;
  //transform: scale(1.01);
  &.left-0 {
    left: 0;
  }
}

.splash {
  position: relative;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  //margin-top: 150px;
  transform-origin: center;
  transform: scale(.88);
}

.vertically-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}