@mixin media-down($point) {
  @media (max-width: ($point - 1)) {
    @content;
  }
}

@mixin media-up($point) {
  @media (min-width: ($point)) {
    @content;
  }
}

@mixin transition($prop, $delay:0.3s, $speed:0.2s) {
  transition: $prop $delay ease-out $speed;
}
